<template>
  <div>
    <v-row justify="center">
      <v-dialog
          v-model="showSlip"
          width="600px"
          @click:outside="hideSlip"
      >
        <v-card>
          <v-card-text>
            <img :src="currentSlipImage" alt="Slip Image" class="mx-auto" style="width: 100%;">
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="secondary darken-1"
                text
                @click="hideSlip">
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  <TemplateFeed>
        <v-card
        slot="first"
        class=" report-card"
        outlined
    >
      <FilterPanel @onFilterUpdate="updateFilters"></FilterPanel>
    </v-card>
       <v-text-field
              slot="searchbar"
              dense
              style="z-index:1"
              v-model="searchValue.search_text"
              placeholder="Search reports"
              class="ma-3 search-bar"
              append-icon="mdi-magnify"
              @keyup.enter="searchByID"
              outlined
          ></v-text-field>
          <v-icon slot="sorticon" class="mr-2" size="24">mdi-swap-vertical</v-icon>
    <div class="pa-3 scrollbar content-height"
         ref="reports"
         slot="second">
          <FeedTests :reports="reports"/>
    </div>

    <div
        class="pa-0 d-flex justify-center align-start flyout-width"
        slot="third"
    >
      <v-row class="mt-4 text-center fill-height d-flex align-center justify-center" v-if="!report">
        <v-col>
          <img width="50%" src="../assets/empty-devices.png" alt="avatar">
          <h3 class="mt-8 message">Click desired report on the left </h3>
          <h3 class="message">to examine it's details</h3>
        </v-col>
      </v-row>
      <div v-else style="width: 100%; height: 100%">
        <Report></Report>
      </div>


    </div>


  </TemplateFeed>
  </div>

</template>

<script>
import TemplateFeed from '../layouts/TemplateFeed.vue'
import FilterPanel from '../components/FilterPanel.vue'
import Report from "../components/Reports/Report.vue";
import FeedTests from '../components/FeedTests.vue'
import {mapActions, mapGetters} from "vuex";


export default {
  components: {TemplateFeed, FilterPanel, Report, FeedTests},
  watch: {
    $route: {
      handler() {
        if (this.$route.params.id) {
          this.selectReport(this.$route.params.id);
        }
      }
    },
    searchValue: {
      handler() {
        setTimeout(() => {
          this.applyReportFilters(this.searchValue);
        }, 3000);
      },
      deep: true
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.selectReport(this.$route.params.id);
    }
    this.getReports();
    this.$refs.reports.onscroll = () => {
      if (this.$refs.reports.scrollTop + this.$refs.reports.clientHeight >= this.$refs.reports.scrollHeight) {
        console.log("reached bottom");
        this.fetchReports({
          next: true
        });
      }
    }
  },
  data: () => ({
    searchValue: {
      search_text: ""
    },
    searchFilterValue: "",
    dialog: true
  }),
  computed: {
    ...mapGetters({
      report: 'report/currentReport',
      reports: 'report/reports',
      showSlip: 'report/showSlip',
      currentSlipImage: 'report/currentSlipImage'

    })
  },

  methods: {
    ...mapActions({"fetchReports": "report/getReports", "selectReport": "report/selectReport", "hideSlip": "report/hideSlip", "filterUpdate": "report/filterUpdate", "setSearchQuery": "report/setSearchQuery", applyReportFilters: "report/applyFilters"}),
    searchByID() {
      this.applyReportFilters(this.searchValue);
    },
    getReports() {
      this.fetchReports(false)
    },
    updateFilters(data) {
      this.filterUpdate(data);
    }
  }

}
</script>

<style scoped>
.sub_div {
   margin-top: 20px;
   position: absolute;
   bottom: 60px;
}
.dots{
  position: static;
  width: 20px;
  height: 20px;
}
.total{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #7A7A7A;
  padding-right: 50px;
}
.numbers{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #7A7A7A;
  line-height: 14px;
  padding-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #F4F9FD;
  height: 20px;
}
.report-card {
  border-radius: 0px;
  width: 100%;
  height: calc(100% - 66px);
  border-top: none;
}

.flyout-width {
  border-left: solid 1px rgb(228, 225, 225);
  width: 100%;
}

.message {
  text-align: center;
  color: var(--v-secondaryText-base);
}

.search-bar {
  background-color: var(--v-general_bckgr-base);
  height:40px;

}
.scrollbar {
  background-color: #eee;
  /*height: calc(100% - 164px);*/
  overflow-y: scroll; /* Add the ability to scroll */
}
.scrollbar::-webkit-scrollbar {
  display: none;
}
.total-reports {
  border-top: 1px solid #eee;
  position: absolute;
  bottom: 200px;
}
.content-height {
  max-height: calc(100vh - 120px);
}

</style>
